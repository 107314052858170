import context from '@truckdown/components-systems';
import { defineComponent, ref, Ref, computed, onMounted } from 'vue';
import { Listing, ListingTemplate, PreferenceType } from '@truckdown/locations';

interface IData {
    showMarker: Ref<boolean>
}

const iconPrefix = '/listings/locations-components/img/';
const iconSuffix = '-pin.png';

const getIconPreference = function (listing: Listing) {
    if (listing.properties.preference?.type == PreferenceType.Preferred) {
        return '-p';
    } else {
        return '';
    }
}

const customClasses = ['msp', 'mspar', 'kyrish', 'rechtien','border'];

const getIconName = function (listing: Listing) {
    if (listing.properties.useCustomPin) {
        if (customClasses.some((c) => c == listing.properties.customClass)) {
            return listing.properties.customClass;
        }
    }

    switch (listing.properties.template) {
        case ListingTemplate.TDPreferred:
            return 'preferred';
        case ListingTemplate.Elite:
            return 'elite';
        case ListingTemplate.Pro:
            return 'pro';
        case ListingTemplate.Branded:
            return 'branded';
        default:
            return 'default';
    }
}

const getIcon = function (listing: Listing) {
    return iconPrefix + getIconName(listing)
        + getIconPreference(listing) + iconSuffix;
}

export default defineComponent({
    name: 'listing-map',
    props: {
        listing: {
            type: Object as () => Listing,
            required: true
        }
    },
    emits: [],
    setup: function (props, ctx) {
        let showMarker = ref<boolean>(false);

        let center = computed(() => {
            return { lat: props.listing.location.lat, lng: props.listing.location.lng };
        });

        let icon = computed(() => {
            return getIcon(props.listing);
        });

        let toggleMarker = function (ev: Event) {
            showMarker.value = !showMarker.value;
        }

        return {
            showMarker,
            center,
            toggleMarker,
            icon
        };
    }
});
