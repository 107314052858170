export class UpdateInfoModel {
    constructor(locationId?: string, name?: string, email?: string, phone?: string, note?: string) {
        if (locationId) {
            this.locationId = locationId as string;
        }
        if (name) {
            this.name = name;
        }
        if (email) {
            this.email = email;
        }
        if (phone) {
            this.phone = phone;
        }
        if (note) {
            this.note = note;
        }
    }

    locationId: string = '';
    name: string = '';
    email: string = '';
    phone: string = '';
    note: string = '';

    toJSON() {
        return {
            lid: this.locationId,
            na: this.name,
            e: this.email,
            p: this.phone,
            no: this.note
        };
    }
}
