(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("Vue"), require("locationsApi"), require("systemsApi"), require("systemsComponents"));
	else if(typeof define === 'function' && define.amd)
		define("locationsComponents", ["Vue", "locationsApi", "systemsApi", "systemsComponents"], factory);
	else if(typeof exports === 'object')
		exports["locationsComponents"] = factory(require("Vue"), require("locationsApi"), require("systemsApi"), require("systemsComponents"));
	else
		root["locationsComponents"] = factory(root["Vue"], root["locationsApi"], root["systemsApi"], root["systemsComponents"]);
})(self, (__WEBPACK_EXTERNAL_MODULE__508__, __WEBPACK_EXTERNAL_MODULE__980__, __WEBPACK_EXTERNAL_MODULE__670__, __WEBPACK_EXTERNAL_MODULE__130__) => {
return 