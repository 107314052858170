<template>
    <div :id="listing.id" :class="mainClass">
        <div class="header">
            <a v-if="isPremium" class="title no-underline" :href="viewUrl" :title="listing.name">
                <span v-if="preference.type == 1" class="icon"><icon type="solid" name="faThumbsUp" aria-hidden="true"></icon></span>
                <span v-if="preference.type == 2" class="icon"><icon type="solid" name="faThumbsDown" aria-hidden="true"></icon></span>
                <span class="icon type" :style="'background: url(\'' + icon + '\') center center no-repeat;'"></span>
                <span class="name">{{ displayName }}</span>
            </a>
            <span v-else class="title">
                <span v-if="preference.type == 1" class="icon"><icon type="solid" name="faThumbsUp" aria-hidden="true"></icon></span>
                <span v-if="preference.type == 2" class="icon"><icon type="solid" name="faThumbsDown" aria-hidden="true"></icon></span>
                <span class="name">{{ displayName }}</span>
            </span>
            <rating-value v-if="ratingValue.count > 0" :listing="listing.id" :value="ratingValue" @rate="openRating"></rating-value>
            <listing-menu :display="displayName" :link-data="listingLinks"
                          @show-on-map="showMap"
                          @forward-listing="showForward"
                          @rate-service="showRatings"
                          @add-note="showNotes"
                          @favorite="showFavorite"
                          @avoid="showAvoid"
                          @show-numbers="showNumbers"
                          @report-update="showReportUpdate"
                          @remove-listing="showRemoveListing"
                          @upgrade-listing="showUpgradeListing"
                          @view-services="showServices"
                          @view-website="viewWebsite"></listing-menu>
        </div>
        <div class="body">
            <div v-if="isPremium" class="logo">
                <a :href="viewUrl" :title="cleanDescription">
                    <suspense>
                        <v-lazy-image height="178" width="178" :src="listing.properties.logoUrl" :alt="listing.name"></v-lazy-image>
                        <template #fallback>
                            <img height="178" width="178" src="data:image/gif;base64,R0lGODlhAQABAAD/ACwAAAAAAQABAAACADs=" :alt="listing.name" />
                        </template>
                    </suspense>
                </a>
            </div>
            <div class="address">
                <hours-status :hours="listing.hours" :is-shop="!listing.properties.mobileOnly && !listing.properties.managedService"></hours-status>
                {{ listing.location.line1 }}<br />
                {{ listing.location.line2 }}
            </div>
            <listing-menu :show-links-only="true" :display="displayName" :link-data="listingLinks"
                          @show-on-map="showMap"
                          @forward-listing="showForward"
                          @rate-service="showRatings"
                          @add-note="showNotes"
                          @favorite="showFavorite"
                          @avoid="showAvoid"
                          @show-numbers="showNumbers"
                          @report-update="showReportUpdate"
                          @remove-listing="showRemoveListing"
                          @upgrade-listing="showUpgradeListing"
                          @view-services="showServices"
                          @view-website="viewWebsite"></listing-menu>
            <div class="phone-numbers">
                <button v-if="!displayNumbers" class="td-btn primary-outlined td-text-uppercase icon-left" @click.prevent="showNumbers">
                    <icon type="solid" name="faPhone" aria-hidden="true"></icon><strong>Show Numbers</strong>
                </button>
                <template v-else>
                    <template v-if="numbers.blocked">
                        <span class="td-banner danger slim no-icon">Our system has detected activity that represents a risk to our system, our users, or the businesses listed. As a result of this risk, your access has been restricted.</span>
                    </template>
                    <template v-if="numbers.captcha">
                        <span class="captcha td-banner warning no-icon slim">Please verify that you are not an automated bot.</span>
                        <div is="vue:captcha" @success="showNumbers" :instance="listing.id"></div>
                    </template>
                    <template v-else>
                        <div v-if="numbers" class="labels">
                            <div v-for="phn in numbers.result">
                                <span>{{ phn.label }}:</span>
                            </div>
                        </div>
                        <div v-if="numbers" class="numbers">
                            <div v-for="phn in numbers.result">
                                <span>{{ phn.display }}</span>
                            </div>
                        </div>
                        <div v-if="numbers?.warning && numbers.warning.length > 0">
                            <span class="td-banner warning no-icon slim">{{ numbers.warning }}</span>
                        </div>
                    </template>
                </template>
            </div>
            <div v-if="isPremium" class="tagline">
                <span v-if="isPremium">{{ listing.properties.tagLine }}</span>
            </div>
            <div v-if="isPremium" class="highlights">
                <span class="highlight" v-for="highlight in listing.properties.highlights" :key="highlight">
                    <icon type="regular" name="faCircleCheck"></icon>{{ highlight }}
                </span>
            </div>
        </div>
        <div v-if="listing.properties.hasEdit" class="td-banner danger">
            <icon name="faTriangleExclamation" type="solid"></icon>
            <p>Changes to your listing have been submitted and are awaiting approval.  Changes will be applied within 1-2 business days.</p>
        </div>
        <div class="footer">
            <button :class="getButtonClass('map', 'td-btn icon-left large')" href="#" title="Show on Map" @click.prevent="showMap">
                <icon type="solid" name="faMap" aria-hidden="true"></icon>Show on Map
            </button>
            <button :class="getButtonClass('forward', 'td-btn icon-left large')" href="#" title="Forward listing to somebody via email or sms text"
                    @click.prevent="showForward">
                <icon type="solid" name="faShare" aria-hidden="true"></icon>Forward
            </button>
            <button :class="getButtonClass('rate', 'td-btn icon-left large')" href="#" title="Rate Service"
                    @click.prevent="showRatings">
                <icon type="solid" name="faStar" aria-hidden="true"></icon>Rate Service
            </button>
            <button v-if="notes.length > 0" :class="getButtonClass('notes', 'td-btn icon-left large')" href="#" title="View Notes"
                    @click.prevent="showNotes">
                <span class="icon">
                    <icon type="solid" name="faStickyNote" aria-hidden="true"></icon>
                    <span class="td-circle-badge">{{ notes.length > 9 ? '9+' : notes.length }}</span>
                </span>
                <span class="title">View Notes</span>
            </button>
            <button v-if="notes.length == 0" :class="getButtonClass('notes', 'td-btn icon-left large')" href="#" title="Add Note"
                    @click.prevent="showNotes">
                <icon type="solid" name="faStickyNote" aria-hidden="true"></icon><span>Add Note</span>
            </button>
            <div class="btn-double">
                <button :class="getButtonClass('favorite', 'td-btn icon-left large favorite-btn')" :title="preferredLabel"
                        @click.prevent="showFavorite">
                    <icon type="solid" name="faThumbsUp" aria-hidden="true"></icon>Favorite
                </button>
                <button :class="getButtonClass('avoid', 'td-btn icon-left large avoid-btn')" title="Remove from current and future search results"
                        @click.prevent="showAvoid">
                    <icon type="solid" name="faThumbsDown" aria-hidden="true"></icon>Avoid
                </button>
            </div>
        </div>
        <div class="data">
            <transition name="expand" appear>
                <listing-map v-if="panelDisplay == 'map'" :listing="listing"></listing-map>
            </transition>
            <transition name="expand" appear>
                <listing-report-update :listing-id="listing.id" v-if="panelDisplay == 'report-update'" @close="panelDisplay = ''"></listing-report-update>
            </transition>
            <transition name="expand" appear>
                <listing-forward :listing-id="listing.id" v-if="panelDisplay == 'forward'" @close="panelDisplay = ''"></listing-forward>
            </transition>
            <transition name="expand" appear>
                <listing-rate v-if="panelDisplay == 'rate'" :listing-id="listing.id" :initial="initialRating" @add-note="addNote" @close="panelDisplay = ''"></listing-rate>
            </transition>
            <transition name="expand" appear>
                <listing-favorite :listing-id="listing.id"
                                  :preference="preference"
                                  :services="services"
                                  :dealers="networks"
                                  v-if="panelDisplay == 'favorite'"
                                  @add-favorite="moveToFavorite"
                                  @remove-favorite="moveFromFavorite"
                                  @update-favorite="updateFavorite"
                                  @add-note="addNote"
                                  @close="panelDisplay = ''"></listing-favorite>
            </transition>
            <transition name="expand" appear>
                <listing-avoid v-if="panelDisplay == 'avoid'"
                               :listing-id="listing.id"
                               :preference="preference"
                               @add-avoid="addAvoid"
                               @remove-avoid="removeAvoid"
                               @add-note="addNote"
                               @close="panelDisplay = ''"></listing-avoid>
            </transition>
            <transition name="expand" appear>
                <listing-notes v-if="panelDisplay == 'notes'"
                               :listing-id="listing.id"
                               :listing-notes="notes"
                               @close="panelDisplay = ''"
                               @add-note="addNote"
                               @edit-note="editNote"
                               @delete-note="deleteNote"></listing-notes>
            </transition>
            <transition name="expand" appear>
                <div v-if="panelDisplay == 'services'" class="services-container">
                    <h3 class="td-h3 my-1 td-text-center">Services & Amenities Offered</h3>
                    <ul class="service-list td-list">
                        <li v-for="service in services" :key="service.code" class="item">
                            <icon type="solid" name="faCircleCheck" aria-hidden="true"></icon>
                            {{ service.name }}
                            <ul v-if="service.amenities.length > 0" class="td-list styled">
                                <li v-for="amenity in service.amenities" :key="amenity.code">{{ amenity.name }}</li>
                            </ul>
                        </li>
                    </ul>
                    <h3 class="td-h3 my-1 td-text-center" v-if="networks.length > 0">Dealer Networks</h3>
                    <ul v-if="networks.length > 0" class="service-list td-list">
                        <li v-for="network in networks" :key="network.code" class="item">
                            <icon type="solid" name="faCircleCheck" aria-hidden="true"></icon>
                            {{ network.name }}
                        </li>
                    </ul>
                </div>
            </transition>
            <transition name="expand" appear>
                <listing-remove :listing-id="listing.id" v-if="panelDisplay == 'remove-listing'" @close="panelDisplay = ''"></listing-remove>
            </transition>
            <transition name="expand" appear>
                <listing-upgrade :listing-id="listing.id" :listing-type="listing.properties.type" v-if="panelDisplay == 'upgrade-listing'" @close="panelDisplay = ''"></listing-upgrade>
            </transition>
        </div>
        <div class="details" v-if="showDetail">
            <div class="column-left">
                <div v-if="listing.properties.description || details.length > 0" class="description td-card grey rounded">
                    <h3 v-if="listing.properties.description">Description</h3>
                    <div v-if="listing.properties.description" v-html="listing.properties.description"></div>
                    <div v-if="details.length > 0" class="additionalData">
                        <h4>Additional Details</h4>
                        <ul class="td-list md-col-2">
                            <li v-for="detail in details" :key="detail.key" class="service">
                                <icon type="solid" name="faCircleCheck" aria-hidden="true"></icon>
                                {{ detail.key }}<span v-if="detail.additionalFee"> ($)</span>
                                <div v-if="(detail.value?.length ?? 0) > 0" class="fee">{{ detail.value }}</div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="services td-card grey rounded" v-if="services.length > 0">
                    <h3>Services & Amenities Offered</h3>
                    <ul class="td-list">
                        <li class="service md-col-2">
                            <div v-for="service in services" :key="service.code" class="item">
                                <icon type="solid" name="faCircleCheck" aria-hidden="true"></icon>
                                {{ service.name }}
                                <ul v-if="service.amenities.length > 0" class="td-list">
                                    <li v-for="amenity in service.amenities" :key="amenity.code">
                                        {{ amenity.name }}<span v-if="amenity.addFee"> ($)</span>
                                        <div v-if="(amenity.detail?.length ?? 0) > 0" class="fee">{{ amenity.detail }}</div>
                                    </li>
                                </ul>
                            </div>
                        </li>
                    </ul>
                </div>
                <div v-if="networks.length > 0" class="dealers td-card grey rounded">
                    <h3>Dealer Networks</h3>
                    <ul class="td-list">
                        <li class="service md-col-2">
                            <div v-for="network in networks" :key="network.code" class="item">
                                <icon type="solid" name="faCircleCheck" aria-hidden="true"></icon>
                                {{ network.name }}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="column-right td-card grey rounded">
                <listing-photos v-if="listing.properties.photoUrls.length > 0" :title="listing.name" :photos="listing.properties.photoUrls"></listing-photos>
                <listing-hours v-if="listing.hoursData && listing.hoursData != null" :listing="listing"></listing-hours>
                <div v-if="paymentImages.length > 0" class="hours-payment td-card rounded">
                    <div class="payment_types">
                        <h4>Accepted Payment Types</h4>
                        <img v-for="image in paymentImages" :key="image.name" :src="image.url" :alt="image.name" width="75" height="48" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>import script from './Listing';
    export default script;</script>

<style lang="less">
    @import "./Listing.less";
</style>