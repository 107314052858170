<template>
    <div class="images">
        <div class="carousel-container">
            <a href="#" :title="title" @click.prevent="open">
                <img class="active" :src="photos[activeIndex]" alt="Listing Image" />
            </a>
        </div>
        <div v-if="photos.length > 1" class="arrow arrow-left" @click.prevent="previous">
            <icon name="faCaretLeft" type="solid" aria-hidden="true"></icon>
        </div>
        <div v-if="photos.length > 1" class="arrow arrow-right" @click.prevent="next">
            <icon name="faCaretRight" type="solid" aria-hidden="true"></icon>
        </div>
        <div class="carousel-indicators" v-if="photos.length > 1">
            <span v-for="(image, index) in photos" :key="index" @click.prevent="goToSlide(index)" :class="{active: index === activeIndex}">
            </span>
        </div>
        <transition name="modal">
            <modal v-if="shownImage >= 0" @close="close" hide-footer class="images-modal">
                <img class="active" :src="photos[shownImage]" alt="Listing Image" />
            </modal>
        </transition>
    </div>
</template>

<script>import script from './ListingPhotos';
    export default script;</script>
<style lang="less">
    @import "./ListingPhotos.less";
</style>