import context from '@truckdown/components-systems';
import { Constants, ServiceModel, ServiceType, ThrottleResult } from '@truckdown/systems';
import { defineComponent, ref, Ref, computed, onMounted, inject } from 'vue';
import { AddLocationAnalyticModel, ILocationActionsService, Listing, ListingNote, ListingPhoneNumber, ListingRatingValue, ListingTemplate, ListingType, PaymentType, PreferenceModel, PreferenceType, ThrottleResponse } from '@truckdown/locations';

import { NoteModel } from '../../Services/NoteModel';

import listingMenu from './ListingMenu/ListingMenu.vue';
import ratingValue from './RatingValue/RatingValue.vue';
import listingHours from './ListingHours/ListingHours.vue';
import listingPhotos from './ListingPhotos/ListingPhotos.vue';
import listingMap from './ListingMap/ListingMap.vue';
import { AdditionalDataModel } from '@truckdown/locations/Models/AdditionalDataModel';

interface ILinkData {
    id: string,
    accountId: string,
    nameForUrl: string,
    showEditLinks: boolean,
    showReportUpdate: boolean,
    showAdminViewLinks: boolean,
    showRemoveLink: boolean,
    showUpgradeLink: boolean,
    showViewServiceLink: boolean,
    showDetailsLink: boolean,
    showWebsiteLink: boolean,
    showPhoneNumbersLink: boolean,
    isFleet: boolean,
    isAuthenticated: boolean,
    editHoursUrl?: string,
    editUrl?: string,
    notesLabel: string,
    preferredLabel: string
}

interface IServiceListModel {
    code: string,
    name: string,
    detail?: string,
    addFee: boolean,
    amenities: IServiceListModel[]
}

interface IPaymentImage {
    name: string,
    url: string
}

interface IData {
    preference: Ref<PreferenceModel>,
    notes: Ref<ListingNote[]>,
    ratingValue: Ref<ListingRatingValue>,
    panelDisplay: Ref<string>,
    numbers: Ref<ThrottleResponse<ListingPhoneNumber[]> | undefined>,
    displayNumbers: Ref<boolean>,
    initialRating: Ref<number>
}

const getInitialData = function (listing: Listing, allServices: ServiceModel[]): IData {
    listing.notes = listing.notes.sort((a, b) => {
        if (new Date(a.date) < new Date(b.date)) {
            return 1;
        }
        if (new Date(a.date) > new Date(b.date)) {
            return -1;
        }
        return 0;
    });

    return {
        preference: ref<PreferenceModel>(listing.properties.preference),
        notes: ref<ListingNote[]>(listing.notes),
        ratingValue: ref<ListingRatingValue>(listing.properties.rating),
        panelDisplay: ref<string>(''),
        numbers: ref<ThrottleResponse<ListingPhoneNumber[]> | undefined>(listing.phoneNumbers.length > 0 ? {
            blocked: false,
            captcha: false,
            warning: '',
            result: listing.phoneNumbers
        } : undefined),
        displayNumbers: ref<boolean>(false),
        initialRating: ref<number>(0)
    };
}

const getAmenity = function (listing: Listing, service: ServiceModel): IServiceListModel {
    let addData = listing.additionalData
        .filter((d) => { return d.isAmentity && d.key == service.code; });

    if (addData.length > 0 && addData[0].value && addData[0].value !== null) {
        return {
            code: service.code,
            name: service.name,
            detail: addData[0].value,
            addFee: addData[0].additionalFee,
            amenities: []
        };
    }
    return {
        code: service.code,
        name: service.name,
        addFee: false,
        amenities: []
    };
}

const getAmenities = function (listing: Listing, service: ServiceModel, allServices: ServiceModel[]): IServiceListModel[] {
    if (service.children.length == 0) {
        return [];
    }

    return allServices.filter((s) => {
        return s.type == ServiceType.Amenity
            && service.children.some((l) => { return l == s.code; })
            && listing.services.some((l) => { return l.code == s.code; });
    }).map((s) => {
        return getAmenity(listing, s);
    });
}

const getServiceListModels = function (listing: Listing, allServices: ServiceModel[]): IServiceListModel[] {
    return allServices.filter((s) => {
        return s.type == ServiceType.Service
            && listing.services.some((l) => { return l.code == s.code; });
    }).map((s) => {
        return {
            code: s.code,
            name: s.name,
            addFee: false,
            amenities: getAmenities(listing, s, allServices)
        } as IServiceListModel;
    });
}

const getDealerNetworks = function (listing: Listing, allServices: ServiceModel[]): IServiceListModel[] {
    return allServices.filter((s) => {
        return s.type == ServiceType.DealerNetwork
            && listing.networks.some((l) => { return l.code == s.code; });
    }).map((s) => {
        return {
            code: s.code,
            name: s.name,
            addFee: false,
            amenities: []
        } as IServiceListModel;
    });
}

const getListingDetails = function (listing: Listing, allServices: ServiceModel[]): AdditionalDataModel[] {
    return listing.additionalData.filter((d) => {
        return !d.isAmentity;
    });
}

const getPaymentImage = function (type: PaymentType): IPaymentImage {
    switch (type) {
        case PaymentType.AmericanExpress: {
            return {
                name: 'American Express',
                url: '/listings/locations-components/img/americanexpress.png'
            };
        }
        case PaymentType.cbCharge: {
            return {
                name: 'cbCharge',
                url: '/listings/locations-components/img/cbcharge.png'
            };
        }
        case PaymentType.ComData: {
            return {
                name: 'ComData',
                url: '/listings/locations-components/img/comdata.png'
            };
        }
        case PaymentType.Discover: {
            return {
                name: 'Discover',
                url: '/listings/locations-components/img/discover.png'
            };
        }
        case PaymentType.EFS: {
            return {
                name: 'EFS',
                url: '/listings/locations-components/img/efs.png'
            };
        }
        case PaymentType.Mastercard: {
            return {
                name: 'Mastercard',
                url: '/listings/locations-components/img/mastercard.png'
            };
        }
        default: {
            return {
                name: 'Visa',
                url: '/listings/locations-components/img/visa.png'
            };
        }
    }
}

const isPremiumListing = function (listing: Listing): boolean {
    return listing.properties.type == ListingType.Branded
        || listing.properties.type == ListingType.Pro
        || listing.properties.type == ListingType.Elite;
}

const getListingLinks = function (detailedView: boolean, nameForUrl: string, listing: Listing, data: IData): ILinkData {
    var isAdmin = context.isInRole(Constants.Roles.CSRRole)
        || context.isInRole(Constants.Roles.SystemRole);

    

    var isOwned = context.isAuthenticated
        && context.isInRole(Constants.Roles.CustomerAdminRole)
        && (listing.accountId == context.user?.accountId
            || listing.accountIds.some((a) => {
                return a == context.user?.accountId
            })
        );

    var isPublished = listing.properties.type != ListingType.None;

    var isPremium = isPremiumListing(listing);

    return {
        id: listing.id,
        accountId: listing.accountId,
        nameForUrl: nameForUrl,
        showAdminViewLinks: isAdmin,
        showEditLinks: isAdmin || isOwned,
        showDetailsLink: (isAdmin || isOwned || isPremium) && !detailedView,
        showRemoveLink: isOwned && isPublished,
        showUpgradeLink: isOwned && listing.properties.type != ListingType.Elite,
        showReportUpdate: !isAdmin && !isOwned,
        showWebsiteLink: detailedView && isPremium && listing.properties.website !== undefined && (listing.properties.website.length > 0),
        showViewServiceLink: isPremium && !detailedView,
        showPhoneNumbersLink: !data.displayNumbers.value,
        isFleet: context.isInRole(Constants.Roles.FleetUserRole),
        isAuthenticated: context.isAuthenticated,
        editHoursUrl: isAdmin ? '/location/admin/hours/' + listing.id : '/location/hours/' + listing.id,
        editUrl: isAdmin ? '/location-admin/edit-location/' + listing.id : '/locations/edit-location/' + listing.id,
        notesLabel: data.notes.value.length == 0 ? 'Add Note' : 'View Notes',
        preferredLabel: data.preference.value.type == PreferenceType.Preferred ? 'Edit Favorite Settings' : 'Add to Favorites List'
    };
}

const getComputed = function (props: any, listing: Listing, allServices: ServiceModel[], data: IData) {
    let nameForUrl = (listing.name + ' ' + listing.location.city + ' ' + listing.location.regionCode)
        .toLowerCase()
        .replace(/[^a-z \+]/g, '')
        .replace(/[ \+]/g, '_');

    return {
        isPremium: computed(() => {
            return isPremiumListing(listing);
        }),
        mainClass: computed(() => {
            let classes: string[] = ['cmp-listing'];
            if (isPremiumListing(listing)) {
                classes.push('premium');
            }
            switch (listing.properties.template) {
                case ListingTemplate.Elite: {
                    classes.push('elite');
                    break;
                }
                case ListingTemplate.TDPreferred: {
                    classes.push('preferred');
                    break;
                }
                case ListingTemplate.Pro: {
                    classes.push('pro');
                    break;
                }
                case ListingTemplate.Branded: {
                    classes.push('branded');
                    break;
                }
                default: {
                    classes.push('stock');
                    break;
                }
            }

            if (listing.properties.customClass && listing.properties.customClass.length > 0) {
                classes.push(listing.properties.customClass);
            }

            return classes.join(' ');
        }),
        preferredLabel: computed(() => {
            if (data.preference.value.type == PreferenceType.Preferred) {
                return 'Edit Favorite Settings';
            }
            return 'Add to Favorites List';
        }),
        viewUrl: computed(() => {
            return '/search/view-detail/'
                + encodeURIComponent(nameForUrl)
                + '/' + encodeURIComponent(listing.id);
        }),
        displayName: computed(() => {
            if (listing.properties.private)
                return listing.name + ' (private)';
            if (listing.properties.type == ListingType.None)
                return listing.name + ' (not published)';
            return listing.name;
        }),
        cleanDescription: computed(() => {
            if (listing.properties.description && listing.properties.description.length > 0) {
                var div = document.createElement("div");
                div.innerHTML = listing.properties.description;
                return div.textContent || div.innerText || listing.name;
            }
            return listing.name;
        }),
        services: computed(() => {
            return getServiceListModels(listing, allServices);
        }),
        networks: computed(() => {
            return getDealerNetworks(listing, allServices);
        }),
        details: computed(() => {
            return getListingDetails(listing, allServices);
        }),
        paymentImages: computed(() => {
            return listing.properties.paymentTypes.map((t) => getPaymentImage(t));
        }),
        icon: computed(() => {
            if (listing.properties.useCustomIcon) {
                if (listing.properties.customClass == "msp")
                    return "/listings/locations-components/img/type_msp.png";

                if (listing.properties.customClass == "mspar")
                    return "/listings/locations-components/img/type_mspar.png";
            }

            switch (listing.properties.type) {
                case ListingType.Elite: {
                    return '/listings/locations-components/img/type_elite.png';
                }

                case ListingType.Pro: {
                    return '/listings/locations-components/img/type_pro.png';
                }

                case ListingType.Branded: {
                    return '/listings/locations-components/img/type_brand.png';
                }

                default: {
                    return '';
                }
            }
        }),
        noteLabel: computed(() => {
            if (data.notes.value.length == 0) {
                return 'Add Note';
            }
            return 'View Notes';
        }),
        noteCount: computed(() => {
            if (data.notes.value.length == 0) {
                return '';
            }
            if (data.notes.value.length > 9) {
                return '9+';
            }
            return '' + data.notes.value.length;
        }),
        listingLinks: computed(() => {
            return getListingLinks(props.showDetail, nameForUrl, listing, data);
        })
    };
}

const submitAnalytics = async function (listing: Listing, fact: string) {
    var model: AddLocationAnalyticModel = {
        locationId: listing.id,
        cube: 'location',
        facts: [
            { "n": fact, "v": 1 }
        ],
        dimensions: [],
        drilledDimensions: [],
    };

    context.getService<ILocationActionsService>('ILocationActionsService')
        .then((service) => {
            service.addLocationAnalytic(model)
                .catch((reason) => {
                    console.log(reason);
                });
        })
        .catch((reason) => {
            console.log(reason);
        });
}

const showMap = function (listing: Listing) {
    context.getService<ILocationActionsService>('ILocationActionsService')
        .then((service) => {
            return service.showOnMap(listing.id)
                .then((val) => {
                    context.analytics({
                        'event': 'Map',
                        'event_category': 'Listing',
                        'event_label': 'Show'
                    });
                });
        })
        .catch((reason) => {
            console.log(reason);
        });
}

const setPanel = function (listing: Listing, data: IData, panel: string) {
    if (data.panelDisplay.value == panel) {
        data.panelDisplay.value = '';
    } else {
        if (panel == 'map') {
            showMap(listing);
        }

        switch (panel) {
            case 'forward': {
                submitAnalytics(listing, 'bf');
                break;
            }
            case 'rate': {
                submitAnalytics(listing, 'br');
                break;
            }
            case 'notes': {
                submitAnalytics(listing, 'bn');
                break;
            }
            case 'favorite': {
                submitAnalytics(listing, 'bfav');
                break;
            }
            case 'avoid': {
                submitAnalytics(listing, 'bav');
                break;
            }
            case 'services': {
                submitAnalytics(listing, 'bsvs');
                break;
            }
            default: {
                break;
            }
        }

        data.panelDisplay.value = panel;
    }
}

let isLoadingNumbers = false;
const showNumbers = function (listing: Listing, data: IData) {
    if (!isLoadingNumbers) {
        isLoadingNumbers = true;

        context.getService<ILocationActionsService>('ILocationActionsService')
            .then((service) => {
                return service.addCall(listing.id)
                    .then((res) => {
                        data.numbers.value = res;
                        if (!res.blocked && !res.captcha) {
                            context.analytics({
                                'event': 'Call',
                                'event_category': 'Listing',
                                'event_label': 'Call'
                            });
                            context.events.emit('add-call-count');
                        }
                        context.events.emit('show-numbers', listing.id);
                        data.displayNumbers.value = true;
                        isLoadingNumbers = false;
                    });
            })
            .catch((reason) => {
                isLoadingNumbers = false;
                console.log(reason);
            });
    }
}

let isLoading = false;
const viewWebsite = function (listing: Listing) {
    if (!isLoadingNumbers) {
        isLoadingNumbers = true;

        context.getService<ILocationActionsService>('ILocationActionsService')
            .then(async (service) => {
                await service.viewWebsite(listing.id);
                window.open(listing.properties.website);
                isLoading = false;
            })
            .catch((reason) => {
                isLoading = false;
                console.log(reason);
            });
    }
}

const getMethods = function (list: string | undefined, listing: Listing, data: IData, props: any, ctx: any) {
    const smoothScroll = inject('smoothScroll') as any;
    return {
        moveToFavorite: function (preference: PreferenceModel) {
            if (preference != null) {
                data.panelDisplay.value = '';
                data.preference.value = preference;
                listing.properties.preference = preference;
            }
            if (list && list !== 'favorite') {
                context.events.emit('move-listing', list, 'favorite', listing);
            }
        },
        moveFromFavorite: function () {
            data.panelDisplay.value = '';
            data.preference.value = {
                type: PreferenceType.None,
                services: []
            } as PreferenceModel;
            listing.properties.preference = data.preference.value;
            if (list && list !== 'listings') {
                context.events.emit('move-listing', list, 'listings', listing);
            }
        },
        addAvoid: function (preference: PreferenceModel) {
            if (preference != null) {
                data.panelDisplay.value = '';
                data.preference.value = preference;
                listing.properties.preference = preference;
            }
            if (list) {
                context.events.emit('move-listing', list, 'avoid', listing);
            }
        },
        removeAvoid: function () {
            data.panelDisplay.value = '';
            data.preference.value = {
                type: PreferenceType.None,
                services: []
            } as PreferenceModel;
            listing.properties.preference = data.preference.value;

            if (list && list == 'avoid') {
                context.events.emit('move-listing', list, 'listings', listing);
            }
        },
        updateFavorite: function (preference: PreferenceModel) {
            if (preference != null) {
                data.panelDisplay.value = '';
                data.preference.value = preference;
                listing.properties.preference = preference;
            }
        },
        showMap: function (ev: Event) {
            if (props.showDetail || props.enableMap) {
                setPanel(listing, data, 'map');
            }
            else {
                showMap(listing);
                context.events.emit('show-map', listing.id);
                let el = document.getElementById('activity-board');
                smoothScroll({
                    scrollTo: el,
                    hash: '#activity-board',
                    offset: -50
                });
            }
        },
        showForward: function (ev: Event) {
            setPanel(listing, data, 'forward');
        },
        showRatings: function (ev: Event) {
            setPanel(listing, data, 'rate');
        },
        openRating: function (count: number) {
            data.initialRating.value = count;
            setPanel(listing, data, 'rate');
        },
        showNotes: function (ev: Event) {
            setPanel(listing, data, 'notes');
        },
        showFavorite: function (ev: Event) {
            setPanel(listing, data, 'favorite');
        },
        showAvoid: function (ev: Event) {
            setPanel(listing, data, 'avoid');
        },
        showReportUpdate: function (ev: Event) {
            setPanel(listing, data, 'report-update');
        },
        showRemoveListing: function (ev: Event) {
            setPanel(listing, data, 'remove-listing');
        },
        showUpgradeListing: function (ev: Event) {
            setPanel(listing, data, 'upgrade-listing');
        },
        showServices: function (ev: Event) {
            setPanel(listing, data, 'services');
        },
        viewWebsite: function (ev: Event) {
            viewWebsite(listing);
        },
        showNumbers: function (ev: Event) {
            if (data.numbers.value
                && data.numbers.value.result !== null
                && data.numbers.value.result.length > 0) {
                context.events.emit('show-numbers', listing.id);
                data.displayNumbers.value = true;
            } else {
                showNumbers(listing, data);
            }
        },
        getButtonClass: function (panel: string, base: string) {
            let suffix = '';
            if (data.panelDisplay.value == panel) {
                suffix = suffix + ' active';
            }
            if (panel != 'map') {
                if (!context.isAuthenticated) {
                    suffix = suffix + ' disabled';
                } else {
                    if (panel == 'favorite'
                        || panel == 'avoid') {
                        if (!context.isInRole(Constants.Roles.FleetUserRole)) {
                            suffix = suffix + ' disabled';
                        }
                    }
                }
            }
            return base + suffix;
        },
        addNote: function (note: NoteModel) {
            let listingNote = {
                id: note._id,
                userId: note.uid,
                user: note.n,
                comment: note.c,
                date: note.d
            } as ListingNote;

            listing.notes.unshift(listingNote);
            data.notes.value = listing.notes;
        },
        editNote: function (note: NoteModel) {
            let listingNote = {
                id: note._id,
                userId: note.uid,
                user: note.n,
                comment: note.c,
                date: note.d
            } as ListingNote;

            listing.notes = listing.notes
                .filter((itm) => itm.id != listingNote.id);
            listing.notes.unshift(listingNote);
            data.notes.value = listing.notes;
        },
        deleteNote: function (noteId: string) {
            listing.notes = listing.notes
                .filter((itm) => itm.id != noteId);
            data.notes.value = listing.notes;
        },
        getAmenityDisplay: function (amenity: IServiceListModel) {
            let suffix = '';
            if (amenity.addFee) {
                suffix = '$';
            }
            if (amenity.detail && amenity.detail.length > 0) {
                if (suffix.length > 0) {
                    suffix = suffix + ' ' + amenity.detail;
                } else {
                    suffix = amenity.detail;
                }
            }
            if (suffix.length > 0) {
                return amenity.name + ' (' + suffix + ')';
            }
            return amenity.name;
        }
    };
}

export default defineComponent({
    name: 'listing',
    components: {
        'listing-menu': listingMenu,
        'rating-value': ratingValue,
        'listing-hours': listingHours,
        'listing-photos': listingPhotos,
        'listing-map': listingMap
    },
    props: {
        list: String,
        listing: Object,
        allServices: Array,
        showDetail: Boolean,
        enableMap: Boolean
    },
    emits: [],
    setup: function (props, ctx) {
        let listing = props.listing as Listing;
        let allServices = props.allServices as ServiceModel[];

        let data = getInitialData(listing, allServices);
        let computedValues = getComputed(props, listing, allServices, data);

        let methods = getMethods(props.list, listing, data, props, ctx);

        onMounted(() => {
            if (props.showDetail) {
                context.analytics({
                    'event': 'View',
                    'event_category': 'Listing',
                    'event_label': 'Details'
                });
            }
        });

        context.events.on('show-numbers', (id: string) => {
            if (id !== listing.id) {
                data.displayNumbers.value = false;
            }
        });

        return {
            ...data,
            ...computedValues,
            ...methods
        };
    }
});