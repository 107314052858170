export class AddRatingModel {
    constructor(locationId?: string, value?: number, isPublic?: boolean, note?: string) {
        if (locationId) {
            this.locationId = locationId;
        }
        if (value) {
            this.value = value;
        }
        if (isPublic) {
            this.isPublic = isPublic;
        }
        if (note) {
            this.note = note;
        }
    }

    locationId: string = '';
    value: number = 5;
    isPublic: boolean = false;
    note: string = '';

    toJSON() {
        return {
            lid: this.locationId,
            v: this.value,
            ip: this.isPublic,
            c: this.note
        };
    }
}
