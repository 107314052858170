import { defineComponent, ref, Ref, computed, onMounted } from 'vue';
import { EntryModel, HoursEntryModel, HoursOfOperationDisplayType, HoursOfOperationHolidaysCondition, HoursOfOperationType, Listing } from '@truckdown/locations';

interface IDayInfo {
    entries: string[],
    message: string
}

const getDisplay = function (listing: Listing, day?: HoursEntryModel): IDayInfo {
    return {
        entries: getTimeEntry(listing, day),
        message: getMessage(listing, day)
    };
}

const getTimeEntry = function (listing: Listing, day?: HoursEntryModel): string[] {
    if (listing.hoursData.type == HoursOfOperationType.Closed)
        return [ 'Closed' ];

    if (listing.hoursData.type == HoursOfOperationType.AlwaysOpen)
        return ['Open 24hrs'];

    if (listing.hoursData.type == HoursOfOperationType.SelectHours) {
        if (day && day.enabled) {
            var entries = day.entries
                .filter((e) => {
                    return e.type == HoursOfOperationDisplayType.Open
                        || !(e.type == HoursOfOperationDisplayType.AfterHours && day.afterHours24);
                })
                .map((e) => {
                    return getTimeEntryValue(listing, e);
                });

            if (entries.length > 0) {
                return entries;
            }

            return ['Closed'];
        }

        return ['Closed'];
    }

    return ['Unknown'];
}

const getTimeEntryValue = function (listing: Listing, entry: EntryModel): string {
    let suffix = '';
    if (entry.type == HoursOfOperationDisplayType.AfterHours) {
        if (!listing.properties.mobileOnly && !listing.properties.managedService)
            suffix = " (road service)";
        else
            suffix = " (emergency service)";
    }
    return getTime(entry.startTime) + ' to ' + getTime(entry.endTime) + suffix;
}

const getTime = function (time: string): string {
    var components = time.split(':');
    if (components.length == 2) {
        let hour = Number(components[0]);
        if (isNaN(hour)) {
            return time;
        }
        if (hour == 12) {
            return '12:00 PM';
        } else if (hour > 12) {
            let initial = (hour - 12) < 10 ? '0' : '';
            return initial + (hour - 12) + ':' + components[1] + ' PM';
        } else {
            if (time == '00:00') {
                return '12:00 AM';
            }
            return time + ' AM';
        }
    }
    return time;
}

const getMessage = function (listing: Listing, day?: HoursEntryModel) {
    if (day) {
        if (listing.hoursData.type == HoursOfOperationType.SelectHours
            && day.enabled && day.afterHours24) {
            if (!listing.properties.mobileOnly && !listing.properties.managedService)
                return '24 hours road service';
            else
                return '24 hours emergency service';
        }
    }
    return '';
}

const getComputed = function (listing: Listing) {
    return {
        showClosedHolidayMessage: computed(() => {
            return listing.hoursData.majorHolidayCondition == HoursOfOperationHolidaysCondition.Closed;
        }),
        showDifferentHolidayMessage: computed(() => {
            return listing.hoursData.majorHolidayCondition == HoursOfOperationHolidaysCondition.LimitedServices;
        }),
        monday: computed(() => {
            return getDisplay(listing, listing?.hoursData.entries["Monday"]);
        }),
        tuesday: computed(() => {
            return getDisplay(listing, listing?.hoursData.entries["Tuesday"]);
        }),
        wednesday: computed(() => {
            return getDisplay(listing, listing?.hoursData.entries["Wednesday"]);
        }),
        thursday: computed(() => {
            return getDisplay(listing, listing?.hoursData.entries["Thursday"]);
        }),
        friday: computed(() => {
            return getDisplay(listing, listing?.hoursData.entries["Friday"]);
        }),
        saturday: computed(() => {
            return getDisplay(listing, listing?.hoursData.entries["Saturday"]);
        }),
        sunday: computed(() => {
            return getDisplay(listing, listing?.hoursData.entries["Sunday"]);
        })
    };
}

export default defineComponent({
    props: {
        listing: Object
    },
    emits: [],
    setup: function (props, ctx) {
        let listing = props.listing as Listing;
        var computed = getComputed(listing);
        return {
            ...computed
        };
    }
});

