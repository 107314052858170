import context from '@truckdown/components-systems';
import { IListingService } from './IListingService';

context.registerService({
    name: 'IListingService',
    activator: async () => {
        var svc = await import(/* webpackChunkName: "ListingService" */ './ListingService');
        return new svc.ListingService(context) as IListingService;
    }
})
