export class AddNoteModel {
    constructor(locationId?: string, note?: string) {
        if (locationId) {
            this.locationId = locationId as string;
        }
        if (note) {
            this.note = note;
        }
    }

    locationId: string = '';
    note: string = '';

    toJSON() {
        return {
            lid: this.locationId,
            c: this.note
        };
    }
}
