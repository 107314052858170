import context from '@truckdown/components-systems';
import { defineComponent, ref, Ref, computed, onMounted } from 'vue';
import { ListingRatingValue } from '@truckdown/locations';

export default defineComponent({
    props: {
        listing: String,
        value: Object
    },
    emits: ['rate'],
    setup: function (props, ctx) {
        let value = props.value as ListingRatingValue;

        return {
            title: computed(() => {
                return value.value + ' out of 5 - ' + value.count + ' ratings.';
            }),
            iconType: function (count: number) {
                var smallCount = count - 0.5;
                if (value.value < smallCount)
                    return 'empty';
                else if (value.value == smallCount)
                    return 'half';
                else
                    return 'full';
            },
            rate: function (ev: Event, count: number) {
                ctx.emit('rate', count);
            }
        };
    }
});
