import context from '@truckdown/components-systems';
import { ClientRestrictionStatus, Constants, RegionModel, ServiceModel, ServiceStatus, ServiceType, ILookupService, IRestrictionManager } from '@truckdown/systems';
import { defineComponent, ref, Ref, ComputedRef, computed, onMounted, watch } from 'vue';
import { City, ICityManager } from '@truckdown/tools';

let taskCount = 0;
let tasks: Map<number, Promise<void>> = new Map<number, Promise<void>>();
const timeOutMS = 300;
const triggerDelayedTask = function <T>(
    dataRequest: () => Promise<T>,
    dataProcess: (data: T) => void) {

    taskCount++;
    let cnt = taskCount;

    var request = new Promise(resolve => setTimeout(resolve, timeOutMS))
        .then(async () => {
            if (tasks.has(cnt)) {
                let data = await dataRequest();

                if (tasks.has(cnt)) {
                    dataProcess(data);
                    tasks.delete(cnt);
                }
            }
        })
        .catch((error) => {
            console.error(error);
            tasks.delete(cnt);
        });

    tasks.clear();
    tasks.set(taskCount, request);
};

interface IData {
    selectedRegion: Ref<string>,
    locationInput: Ref<string>,
    selectedCity: Ref<City | undefined>,
    cities: Ref<City[]>,
    serviceInput: Ref<string>,
    selectedServices: Ref<ServiceModel[]>,
    showDropdown: Ref<string>,
    allowOptions: Ref<boolean>,
    allowAdvanced: Ref<boolean>,
    amenityInput: Ref<string>,
    suppressManaged: Ref<boolean>,
    searchOnDistance: Ref<boolean>,
    selectedAmenities: Ref<ServiceModel[]>,
    showOptions: Ref<boolean>,
    locationError: Ref<string | undefined>,
    serviceError: Ref<string | undefined>,
    showCaptcha: Ref<boolean>,
    isBlocked: Ref<boolean>,
    currentLocationLoading: Ref<boolean>,
    allServices: Ref<ServiceModel[]>,
    allRegions: Ref<RegionModel[]>
}

const getSelectedServices = function (props: any, allServices: ServiceModel[]): ServiceModel[] {
    let selectedServices: ServiceModel[] = [];
    if (props.serviceCode) {
        let svc = allServices.find((s) => s.code == props.serviceCode);
        if (svc) {
            selectedServices.push(svc);
        }
    } else {
        if (context.isInRole(Constants.Roles.FleetUserRole)) {
            let claims = context.claims.filter((c) => c.type == 'search_default_service');
            if (claims && claims.length > 0) {
                for (let i = 0; i < claims.length; i++) {
                    let svc = allServices.find((s) => s.code == claims[i].val);
                    if (svc) {
                        selectedServices.push(svc);
                    }
                }
            }
        }
    }
    return selectedServices;
};

const getInitialData = function (props: any): IData {
    let selectedServices = getSelectedServices(props, (props.services ?? []) as ServiceModel[]);
    return {
        selectedRegion: ref<string>(''),
        locationInput: ref<string>(''),
        selectedCity: ref<City | undefined>(),
        cities: ref<City[]>([]),
        serviceInput: ref<string>(''),
        selectedServices: ref<ServiceModel[]>(selectedServices),
        showDropdown: ref<string>(''),
        allowOptions: ref<boolean>(context.isAuthenticated),
        allowAdvanced: ref<boolean>(context.isInRole(Constants.Roles.FleetUserRole)),
        amenityInput: ref<string>(''),
        suppressManaged: ref<boolean>(context.settings['hide_managed_services'] == 'True'),
        searchOnDistance: ref<boolean>(context.settings['search_distance_search'] == 'True'),
        selectedAmenities: ref<ServiceModel[]>([]),
        showOptions: ref<boolean>(false),
        locationError: ref<string | undefined>(),
        serviceError: ref<string | undefined>(),
        showCaptcha: ref<boolean>(props.status == 'Captcha'),
        isBlocked: ref<boolean>(props.status == 'Blocked'),
        currentLocationLoading: ref<boolean>(false),
        allServices: ref<ServiceModel[]>(props.services ?? []),
        allRegions: ref<RegionModel[]>(props.regions ?? [])
    }
}

const getRegionComputed = function (data: IData) {
    return {
        canadianRegions: computed(() => {
            return data.allRegions.value
                .filter((rgn) => rgn.country === 'CA');
        }),
        usRegions: computed(() => {
            return data.allRegions.value
                .filter((rgn) => rgn.country === 'US');
        }),
        isSearchType: computed(() => {
            return data.selectedRegion.value == 'account'
                || data.selectedRegion.value == 'phone';
        })
    }
}

const getLocationComputed = function (data: IData) {
    return {
        locationPlaceholder: computed(() => {
            switch (data.selectedRegion.value) {
                case 'phone':
                    return 'Which phone number?';

                case 'account':
                    return 'Which account number?';

                default:
                    return 'Where do you need service?';
            };
        }),
        showCurrentLocation: computed(() => {
            if (data.selectedRegion.value == 'phone'
                || data.selectedRegion.value == 'account') {
                return false;
            }

            if (data.selectedCity.value) {
                return false;
            }

            if (navigator.geolocation) {
                return true;
            }

            return false;
        })
    }
}

interface ServiceComputed {
    filteredServices: ComputedRef<ServiceModel[]>,
    filteredNetworks: ComputedRef<ServiceModel[]>
}

const getServiceComputed = function (data: IData): ServiceComputed {
    return {
        filteredServices: computed(() => {
            return data.allServices.value
                .filter((itm) => {
                    return itm.type === ServiceType.Service
                        && (
                            data.serviceInput.value.length === 0
                            || itm.tags.some((tg) => {
                                return tg.toLowerCase().indexOf(data.serviceInput.value.toLowerCase()) >= 0;
                            })
                            || itm.name.toLowerCase().indexOf(data.serviceInput.value.toLowerCase()) >= 0)
                        && !data.selectedServices.value.some((sel) => {
                            return itm.code == sel.code;
                        });
                });
        }),
        filteredNetworks: computed(() => {
            return data.allServices.value
                .filter((itm) => {
                    return itm.type === ServiceType.DealerNetwork
                        && (
                            data.serviceInput.value.length === 0
                            || itm.tags.some((tg) => {
                                return tg.toLowerCase().indexOf(data.serviceInput.value.toLowerCase()) >= 0;
                            })
                            || itm.name.toLowerCase().indexOf(data.serviceInput.value.toLowerCase()) >= 0)
                        && !data.selectedServices.value.some((sel) => {
                            return itm.code == sel.code;
                        });
                });
        })
    }
}

const getOptionsComputed = function (data: IData) {
    let amenities = computed(() => {
        return data.allServices.value
            .filter((itm) => {
                return itm.type === ServiceType.Amenity
                    && data.selectedServices.value.some(s => s.children.indexOf(itm.code) >= 0);
            });
    });
    return {
        amenities: amenities,
        filteredAmenities: computed(() => {
            return amenities.value
                .filter((itm) => {
                    return (data.amenityInput.value.length === 0
                        || itm.tags.some((tg) => {
                            return tg.toLowerCase().indexOf(data.amenityInput.value.toLowerCase()) >= 0;
                        })
                        || itm.name.toLowerCase().indexOf(data.amenityInput.value.toLowerCase()) >= 0)
                        && !data.selectedAmenities.value.some((sel) => {
                            return itm.code == sel.code;
                        });
                });
        }),
        hasOptions: computed(() => {
            if (data.selectedRegion.value == 'account'
                || data.selectedRegion.value == 'phone') {
                return false;
            }
            return amenities.value.length > 0 || context.isInRole(Constants.Roles.FleetUserRole);
        })
    }
}

const asyncGeoLocation = function (): Promise<City> {
    if (!navigator.geolocation) {
        return new Promise<City>((_, reject) => {
            reject();
        });
    }

    return new Promise<City>((resolve, reject) => {
        navigator.geolocation.getCurrentPosition(async (position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;
            let city = {
                id: '',
                name: 'Current Location',
                point: {
                    lat: latitude,
                    lng: longitude
                },
                country: '',
                region: '',
                seo: false
            };
            resolve(city);
        }, () => {
            reject();
        }, {
            enableHighAccuracy: false,
            timeout: 5000,
            maximumAge: 3600000
        });
    });
}

const getLocationMethods = function (data: IData) {
    return {
        resetLocation: function () {
            data.locationInput.value = '';
            tasks.clear();
            data.cities.value = [];
            data.selectedCity.value = undefined;
            if (data.showDropdown.value == 'locations') {
                data.showDropdown.value = '';
            }
        },
        triggerLocationLookup: function (input: string) {
            triggerDelayedTask(async () => {
                let service = await context.getService<ICityManager>('ICityManager');

                return await service.search(input, {
                    rgn: data.selectedRegion.value,
                    skipCities: false,
                    skipGeo: false,
                    limit: 15
                });
            }, (result) => {
                data.cities.value = result;
                if (data.showDropdown.value == '') {
                    data.showDropdown.value = 'locations';
                }
            });
        },
        selectCity: function (city?: City) {
            this.resetLocation();
            data.selectedCity.value = city;
        },
        getCityDisplay: function (city: City) {
            if (!city.id || city.id.length === 0) {
                return city.name;
            } else {
                return city.name + ', ' + city.region;
            }
        },
        enterLocation: function () {
            if (data.cities.value.length > 0) {
                this.selectCity(data.cities.value[0]);
            }
        },
        focusLocations: function () {
            if (data.cities.value.length > 0) {
                data.showDropdown.value = 'locations';
            }
        },
        useCurrentLocation: function () {
            data.currentLocationLoading.value = true;
            let component = this;
            asyncGeoLocation()
                .then((city) => {
                    component.selectCity(city);
                    data.currentLocationLoading.value = false;
                })
                .catch((reason) => {
                    console.log(reason);
                    data.currentLocationLoading.value = false;
                })
        }
    }
}

const getServiceMethods = function (data: IData, serviceComputed: ServiceComputed) {
    return {
        selectService: function (service: ServiceModel) {
            if (context.isInRole(Constants.Roles.FleetUserRole)) {
                data.selectedServices.value.push(service);
            } else {
                data.selectedServices.value = [service];
            }
            if (data.showDropdown.value == 'services') {
                data.showDropdown.value = '';
            }
        },
        deselectService: function (service: ServiceModel) {
            data.selectedServices.value = data.selectedServices.value.filter((itm) => {
                return itm.code !== service.code;
            });
        },
        focusService: function () {
            data.showDropdown.value = 'services';
        },
        enterService: function () {
            if (serviceComputed.filteredServices.value.length > 0) {
                this.selectService(serviceComputed.filteredServices.value[0]);
                if (document.activeElement !== null
                    && (document.activeElement as HTMLElement).blur) {
                    (document.activeElement as HTMLElement).blur();
                }
            } else if (serviceComputed.filteredNetworks.value.length > 0) {
                this.selectService(serviceComputed.filteredNetworks.value[0]);
                if (document.activeElement !== null
                    && (document.activeElement as HTMLElement).blur) {
                    (document.activeElement as HTMLElement).blur();
                }
            }
        },
        closeServices: function () {
            if (data.showDropdown.value == 'services') {
                data.showDropdown.value = '';
            }
        }
    }
}

const getOptionsMethods = function (data: IData) {
    return {
        deselectAmenity: function (item: ServiceModel) {
            data.selectedAmenities.value = data.selectedAmenities.value
                .filter((itm) => {
                    return itm.code !== item.code;
                });
        },
        selectAmenity: function (item: ServiceModel) {
            data.selectedAmenities.value.push(item);
        },
        focusAmenity: function () {
            data.showDropdown.value = 'amenities';
        },
        toggleOptions: function () {
            data.showOptions.value = !data.showOptions.value;
        },
        closeAmenities: function () {
            if (data.showDropdown.value == 'amenities') {
                data.showDropdown.value = '';
            }
        }
    }
}

const getSearchMethods = function (data: IData) {
    const getServiceName = function (services: ServiceModel[]) {
        if (services.length > 1) {
            return 'multiple-services';
        } else {
            return encodeURIComponent(services[0].name.toLowerCase().replace(/\s/g, '-'));
        }
    }

    const getServiceQuery = function (services: ServiceModel[]) {
        return services.map(s => s.code).join('-');
    }

    const getLocationName = function (city: City) {
        return encodeURIComponent(city.name);
    }

    const getOptionsQuery = function () {
        let res = '';
        if (data.selectedAmenities.value.length > 0) {
            res = res + '&ams=' + data.selectedAmenities.value.map(s => s.code).join('-');
        }
        if (data.allowAdvanced.value && data.searchOnDistance.value) {
            res = res + '&sod=true';
        }
        if (data.allowAdvanced.value && data.suppressManaged.value) {
            res = res + '&sms=true';
        }
        return res;
    }

    const getSearchUrl = function (city: City, services: ServiceModel[]) {
        if (city.id.length > 0) {
            return '/search/results/' + getServiceName(services)
                + '/' + getLocationName(city)
                + '/' + encodeURIComponent(city.id)
                + '?svs=' + getServiceQuery(services)
                + getOptionsQuery();
        } else {
            return '/search/results/' + getServiceName(services)
                + '/' + getLocationName(city)
                + '?svs=' + getServiceQuery(services)
                + '&lat=' + city.point.lat
                + '&lng=' + city.point.lng
                + '&ctryCd=' + encodeURIComponent(city.country)
                + '&rgnCd=' + encodeURIComponent(city.region)
                + getOptionsQuery();
        }
    }

    const runPointSearch = function () {
        let city = data.selectedCity.value;
        if (!city) {
            data.locationError.value = 'A location is required.';
        }
        let services = data.selectedServices.value;
        if (services.length == 0) {
            data.serviceError.value = 'A service is required.';
        }

        if (!data.locationError.value && !data.serviceError.value) {
            window.location.href = getSearchUrl(city!, services!);
        }
    }

    const runAccountSearch = function () {
        let accountNumber = data.locationInput.value.replace(/\D/g, '');
        if (isNaN(parseInt(accountNumber))) {
            data.locationError.value = 'Invalid account number.';
        } else {
            window.location.href = '/search/results/act/' + encodeURIComponent(accountNumber);
        }
    }

    const runPhoneSearch = function () {
        let phone = data.locationInput.value.replace(/\D/g, '');
        if (phone.length == 10 || phone.length == 11) {
            window.location.href = '/search/results/phn/' + encodeURIComponent(phone);
        } else {
            data.locationError.value = 'Invalid phone number.';
        }
    }

    var isRunning = false;

    return {
        captchaSuccess: function () {
            data.showCaptcha.value = false;
        },
        runSearch: function () {
            if (!isRunning) {
                isRunning = true;
                try {
                    data.locationError.value = undefined;
                    data.serviceError.value = undefined;

                    switch (data.selectedRegion.value) {
                        case 'phone':
                            runPhoneSearch();
                            break;

                        case 'account':
                            runAccountSearch();
                            break;

                        default:
                            runPointSearch();
                            break;
                    };
                }
                catch (err) {
                    console.log(err);
                }
                finally {
                    isRunning = false;
                }
            }
        }
    }
}

export default defineComponent({
    props: {
        regions: Array,
        services: Array,
        status: String,
        serviceCode: String
    },
    emits: [],
    setup: function (props) {
        let data = getInitialData(props);
        let regionComputed = getRegionComputed(data);
        let locationComputed = getLocationComputed(data);
        let serviceComputed = getServiceComputed(data);
        let optionsComputed = getOptionsComputed(data);
        let locationMethods = getLocationMethods(data);
        let serviceMethods = getServiceMethods(data, serviceComputed);
        let optionsMethods = getOptionsMethods(data);
        let searchMethods = getSearchMethods(data);

        if (data.allRegions.value.length == 0 || data.allServices.value.length == 0) {
            onMounted(async () => {
                var lookups = await context.getService<ILookupService>('ILookupService');
                if (data.allRegions.value.length == 0) {
                    data.allRegions.value = await lookups.getRegions();
                }
                if (data.allServices.value.length == 0) {
                    data.allServices.value = await lookups.getServices({
                        sites: [context.system],
                        status: ServiceStatus.Active,
                        types: [ServiceType.Service, ServiceType.DealerNetwork, ServiceType.Amenity]
                    });
                    data.selectedServices.value = getSelectedServices(props, data.allServices.value);
                }
            });
        }

        watch(data.selectedRegion, () => {
            locationMethods.resetLocation();
        });

        watch(data.locationInput, (newVal: string) => {
            if (!regionComputed.isSearchType.value && newVal.length > 0) {
                locationMethods.triggerLocationLookup(newVal);
            }
        });

        context.events.on('captcha-success', function () {
            data.showCaptcha.value = false;
        });

        return {
            ...data,
            ...regionComputed,
            ...locationComputed,
            ...serviceComputed,
            ...optionsComputed,
            ...locationMethods,
            ...serviceMethods,
            ...optionsMethods,
            ...searchMethods
        };
    }
});


