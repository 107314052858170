<template>
    <div class="hours-container td-card rounded">
        <h4>Hours of Operation </h4>
        <span class="td-badge dark"><icon type="solid" name="faClock" aria-hidden="true"></icon>{{ listing.hoursData.timezone }}</span>
  
        <span class="td-badge danger" v-if="showDifferentHolidayMessage">
            <icon type="solid" name="faCircleExclamation" aria-hidden="true"></icon>Hours/services may differ on major holidays
        </span>
        <span v-if="showClosedHolidayMessage" class="td-badge danger"><icon type="solid" name="faCircleExclamation" aria-hidden="true"></icon>Closed on major holidays</span>

        <div class="time">
            <div class="day">Monday</div>
            <div>
                <p v-for="entry in monday.entries" class="message ma-0">{{ entry }} </p>
                <span v-if="monday.message.length > 0" class="message">{{ monday.message }}</span>
            </div>
        </div>
        <div class="time">
            <div class="day">Tuesday</div>
            <div>
                <p v-for="entry in tuesday.entries" class="message ma-0">{{ entry }}</p>
                <span v-if="tuesday.message.length > 0" class="message">{{ tuesday.message }}</span>
            </div>
        </div>
        <div class="time">
            <div class="day">Wednesday</div>
            <div>
                <p v-for="entry in wednesday.entries" class="message ma-0">{{ entry }}</p>
                <span v-if="wednesday.message.length > 0" class="message">{{ wednesday.message }}</span>
            </div>
        </div>
        <div class="time">
            <div class="day">Thursday</div>
            <div>
                <p v-for="entry in thursday.entries" class="message ma-0">{{ entry }}</p>
                <span v-if="thursday.message.length > 0" class="message">{{ thursday.message }}</span>
            </div>
        </div>
        <div class="time">
            <div class="day">Friday </div>
            <div>
                <p v-for="entry in friday.entries" class="message ma-0">{{ entry }}</p>
                <span v-if="friday.message.length > 0" class="message">{{ friday.message }}</span>
            </div>
        </div>
        <div class="time">
            <div class="day">Saturday</div>
            <div>
                <p v-for="entry in saturday.entries" :class="['message', 'ma-0', {'td-text-danger': entry.includes('Closed')}]">{{ entry }}</p>
                <span v-if="saturday.message.length > 0" class="message">{{ saturday.message }}</span>
            </div>
        </div>
        <div class="time">
            <div class="day">Sunday</div>
            <div>
                <p v-for="entry in sunday.entries" :class="['message', 'ma-0', {'td-text-danger': entry.includes('Closed')}]">{{ entry }}</p>
                <span v-if="sunday.message.length > 0" class="message">{{ sunday.message }}</span>
            </div>
        </div>
    </div>
</template>
<script>import script from './ListingHours';
    export default script;</script>