export class EditNoteModel {
    constructor(id?: string, note?: string) {
        if (id) {
            this.id = id as string;
        }
        if (note) {
            this.note = note;
        }
    }

    id: string = '';
    note: string = '';

    toJSON() {
        return {
            _id: this.id,
            c: this.note
        };
    }
}
