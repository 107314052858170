
<template>
    <div v-if="showHours" class="cmp-hours-status">
        <span :class="iconStyle">
            <icon type="solid" :name="iconName"></icon>
        </span>&nbsp;
        <span>{{ statusMessage }}</span>
        <br v-if="message.length > 0" /><span v-if="message.length > 0" class="message">{{ message }}</span>
    </div>
</template>

<script>import script from './HoursStatusComponent';
    export default script;</script>