<template>
    <div v-if="!showLinksOnly" class="icon menu">
        <a class="header-item" href="#" title="Open menu" @click.prevent="toggleMenu" aria-label="Open menu" v-click-outside="closeMenu">
            <icon type="solid" name="faBars"></icon>
        </a>
        <div v-if="showMenu" class="listing-menu">
            <ul class="td-list">
                <template v-if="linkData.showDetailsLink">
                    <li><a class="td-link" :href="'/search/view-detail/' + linkData.nameForUrl + '/' + linkData.id" :title="display"><strong>{{ display }}</strong></a></li>
                </template>
                <template v-else>
                    <li><span class="company-name"><strong>{{ display }}</strong></span></li>
                </template>
                <li v-if="linkData.showPhoneNumbersLink"><a class="td-link" href="#" @click.prevent="clickShowNumbers" title="Show Phone Numbers">Show Phone Numbers</a></li>

                <li class="spacer"></li>
                <li v-if="linkData.showViewServiceLink"><a class="td-link" href="#" @click.prevent="clickViewServices" title="View Services">View Services</a></li>
                <li v-if="linkData.showWebsiteLink"><a class="td-link"  href="#" @click.prevent="clickViewWebsite" rel="nofollow" title="View Website">View Website</a></li>
                <li><a class="td-link" href="#" @click.prevent="showOnMap" title="Show on Map">Show on Map</a></li>

                <li class="spacer"></li>
                <li v-if="linkData.showEditLinks"><a class="td-link" :href="linkData.editUrl" title="Edit Listing">Edit Listing</a></li>
                <li v-if="linkData.showEditLinks"><a class="td-link" :href="linkData.editHoursUrl" title="Edit Hours">Edit Hours</a></li>
                <li v-if="linkData.showReportUpdate"><a class="td-link" href="#" @click.prevent="clickReportUpdate" title="Report a Location Update">Report a Location Update</a></li>
                <li v-if="linkData.showAdminViewLinks"><a class="td-link" :href="'/accounts-admin/view-account/' + linkData.accountId" title="View Account">View Account</a></li>
                <li v-if="linkData.showAdminViewLinks"><a class="td-link" :href="'/location-admin/view-location/' + linkData.id" title="View Listing">View Listing</a></li>

                <li v-if="linkData.showRemoveLink"><a class="td-link" href="#" @click.prevent="clickRemoveListing" title="Remove Listing">Remove Listing</a></li>
                <li v-if="linkData.showUpgradeLink"><a class="td-link" href="#" @click.prevent="clickUpgradeListing" title="Upgrade Listing">Upgrade Listing</a></li>

                <li v-if="linkData.isAuthenticated"><a class="td-link" href="#" @click.prevent="clickForwardListing" title="Forward Listing">Forward Listing</a></li>
                <li v-if="linkData.isAuthenticated"><a class="td-link" href="#" @click.prevent="clickRateService" title="Rate Service">Rate Service</a></li>
                <li v-if="linkData.isAuthenticated"><a class="td-link" href="#" @click.prevent="clickAddNote" :title="linkData.notesLabel">{{ linkData.notesLabel }}</a></li>

                <li v-if="linkData.isFleet"><a href="#" class="td-link" @click.prevent="clickAddFavorite" :title="linkData.preferredLabel">{{ linkData.preferredLabel }}</a></li>
                <li v-if="linkData.isFleet"><a href="#" class="td-link" @click.prevent="clickAddDnu" title="Avoid this Vendor">Avoid this Vendor</a></li>

                <li v-if="!linkData.isAuthenticated" class="spacer"></li>
                <li v-if="!linkData.isAuthenticated"><a class="td-link" href="/accounts/create-or-signin" title="Create a free account or login to unlock features">Create a free account or login to unlock features</a></li>
            </ul>
        </div>
    </div>
    <div v-if="showLinksOnly" class="cmp-listing-links">
        <a v-if="linkData.showEditLinks" class="td-badge dark no-underline ma-0" :href="linkData.editUrl" title="Edit Listing">
            <icon type="solid" name="faCircleInfo" aria-hidden="true"></icon>Edit Listing
        </a>
        <a v-if="linkData.showEditLinks" class="td-badge dark no-underline ma-0" :href="linkData.editHoursUrl" title="Edit Hours">
            <icon type="solid" name="faCalendarDays" aria-hidden="true"></icon>Edit Hours
        </a>
        <a v-if="linkData.showReportUpdate" class="td-badge dark no-underline ma-0" href="#" @click.prevent="clickReportUpdate" title="Report a Location Update">
            <icon type="solid" name="faCircleInfo" aria-hidden="true"></icon>Update Info
        </a>
        <a v-if="linkData.showAdminViewLinks" class="td-badge dark no-underline ma-0" :href="'/accounts-admin/view-account/' + linkData.accountId" title="View Account">
            <icon type="solid" name="faEye" aria-hidden="true"></icon>View Account
        </a>
        <a v-if="linkData.showAdminViewLinks" class="td-badge dark no-underline ma-0" :href="'/location-admin/view-location/' + linkData.id" title="View Listing">
            <icon type="solid" name="faEye" aria-hidden="true"></icon>View Listing
        </a>
        <a v-if="linkData.showRemoveLink" class="td-badge dark no-underline ma-0" href="#" @click.prevent="clickRemoveListing" title="Remove Listing">
            <icon type="solid" name="faXmark" aria-hidden="true"></icon>Remove Listing
        </a>
        <a v-if="linkData.showUpgradeLink" class="td-badge dark no-underline ma-0" href="#" @click.prevent="clickUpgradeListing" title="Upgrade Listing">
            <icon type="solid" name="faCircleUp" aria-hidden="true"></icon>Upgrade Listing
        </a>
        <a v-if="linkData.showViewServiceLink" class="td-badge dark no-underline ma-0" href="#" @click.prevent="clickViewServices" title="View Services">
            <icon type="solid" name="faScrewdriverWrench" aria-hidden="true"></icon>View Services
        </a>
        <a v-if="linkData.showDetailsLink" class="td-badge dark no-underline ma-0" :href="'/search/view-detail/' + linkData.nameForUrl + '/' + linkData.id" :title="display">
            <icon type="solid" name="faScrewdriverWrench" aria-hidden="true"></icon>Vendor Details
        </a>
        <a v-if="linkData.showWebsiteLink" class="td-badge dark no-underline ma-0" href="#" @click.prevent="clickViewWebsite" rel="nofollow" title="View Website">
            <icon type="solid" name="faEye" aria-hidden="true"></icon>View Website
        </a>
    </div>
</template>

<script>import script from './ListingMenu';
    export default script;</script>

<style lang="less">
    @import "./ListingMenu.less";
</style>
