import { Constants } from '@truckdown/systems';
import context from '@truckdown/components-systems';
import { defineComponent, ref, Ref, computed, onMounted } from 'vue';

export default defineComponent({
    name: 'signin-or-upgrade',
    props: {

    },
    emits: [],
    setup: function (props, ctx) {
        const isAuth = context.isAuthenticated;
        const isAce = context.isInRole(Constants.Roles.FleetUserRole);

        const getUrl = function (path: string) {
            let currentUrl = window.location.pathname + window.location.search + window.location.hash;
            return path + '?returnUrl=' + encodeURIComponent(currentUrl);
        }

        const returnUrl = encodeURIComponent(window.location.pathname + window.location.search);

        return {
            isAuth,
            isAce,
            getUrl,
            returnUrl
        };
    }
});
