import { defineComponent, ref, Ref, computed, onMounted } from 'vue';
import { HoursDisplayModel, HoursOfOperationDisplayType } from '@truckdown/locations';

const getComputed = function (hours: HoursDisplayModel, isShop: boolean) {
    let iconName = '',
        iconStyle = '',
        statusMessage = '',
        message = '',
        showHours = false;

    if (hours) {
        message = hours.message;
        switch (hours.type) {
            case HoursOfOperationDisplayType.Open: {
                iconName = 'faCircleCheck';
                iconStyle = 'icon open';
                statusMessage = 'Open';
                showHours = true;
                break;
            }
            case HoursOfOperationDisplayType.Closed: {
                iconName = 'faLock';
                iconStyle = 'icon closed';
                statusMessage = 'Closed'
                showHours = true;
                break;
            }
            case HoursOfOperationDisplayType.AfterHours: {
                iconName = 'faCircleExclamation';
                iconStyle = 'icon afterhours';
                if (isShop) {
                    statusMessage = 'Road Service Only'
                } else {
                    statusMessage = 'Emergency Services Only'
                }
                showHours = true;
                break;
            }
            default: {
                showHours = false;
                break;
            }
        }
    }

    return {
        iconName: computed(() => {
            return iconName;
        }),
        iconStyle: computed(() => {
            return iconStyle;
        }),
        statusMessage: computed(() => {
            return statusMessage;
        }),
        message: computed(() => {
            return message;
        }),
        showHours: computed(() => {
            return showHours;
        })
    };
}

export default defineComponent({
    props: {
        hours: Object,
        isShop: Boolean
    },
    emits: [],
    setup: function (props, ctx) {
        let status = props.hours as HoursDisplayModel;
        var computed = getComputed(status, props.isShop);
        return {
            ...computed
        };
    }
});
