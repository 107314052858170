import { defineComponent, ref, Ref, computed, onMounted } from 'vue';

interface IData {
    activeIndex: Ref<number>,
    shownImage: Ref<number | undefined>
}

const getData = function () {
    return {
        activeIndex: ref<number>(0),
        shownImage: ref<number | undefined>()
    };
}

const getMethods = function (props: any, data: IData) {
    return {
        previous: function (ev: Event) {
            if (data.activeIndex.value > 0) {
                data.activeIndex.value = data.activeIndex.value - 1;
            }
        },
        next: function (ev: Event) {
            if (data.activeIndex.value < (props.photos.length - 1)) {
                data.activeIndex.value = data.activeIndex.value + 1;
            }
        },
        open: function (ev: Event) {
            data.shownImage.value = data.activeIndex.value;
        },
        close: function (ev: Event) {
            data.shownImage.value = undefined;
        },
        goToSlide: function (idx: number) {
            if (idx > 0 && idx < (props.photos.length - 1)) {
                data.activeIndex.value = idx;
            }
        }
    };
}

export default defineComponent({
    props: {
        title: String,
        photos: Array
    },
    emits: [],
    setup: function (props, ctx) {
        let data = getData();
        let methods = getMethods(props, data);


        return {
            ...data,
            ...methods
        };
    }
});


