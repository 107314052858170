<template>
    <div class="cmp-signin-or-upgrade">
        <div v-if="$slots.heading" class="heading">
            <slot name="heading"></slot>
        </div>
        <div v-if="$slots.subheading" class="subheading">
            <slot name="subheading"></slot>
        </div>
        <div>
            <a class="td-btn secondary" v-if="!isAuth" href="/accounts/create-or-signin" title="Register / Sign in">Register / Login</a>
            <a class="td-btn secondary" v-if="isAuth && !isAce" :href="'/accounts/fleet-upgrade?returnUrl=' + returnUrl" title="Upgrade / Fleet">Upgrade / Fleet</a>
            <a class="learn-more td-link td-text-secondary" href="/shop/fleets" title="Learn more about the feature">(More about the feature)</a>
        </div>
    </div>
</template>

<script>import script from './SigninOrUpgrade';
    export default script;</script>

<style lang="less">
    @import "./SigninOrUpgrade.less";
</style>
