export class ForwardLocationModel {
    constructor(locationId?: string, address?: string) {
        if (locationId) {
            this.locationId = locationId as string;
        }
        if (address) {
            this.address = address;
        }
    }

    locationId: string = '';
    address: string = '';

    toJSON() {
        return {
            lid: this.locationId,
            add: this.address
        };
    }
}
