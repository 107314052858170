<template>
    <span class="rating-value">
        <a href="#" :title="title" @click.prevent="rate($event, 1)">
            <icon v-if="iconType(1) == 'empty'" type="regular" name="faStar" aria-hidden="true"></icon>
            <icon v-if="iconType(1) == 'half'" type="regular" name="faStarHalfStroke" aria-hidden="true"></icon>
            <icon v-if="iconType(1) == 'full'" type="solid" name="faStar" aria-hidden="true"></icon>
        </a>
        <a href="#" :title="title" @click.prevent="rate($event, 2)">
            <icon v-if="iconType(2) == 'empty'" type="regular" name="faStar" aria-hidden="true"></icon>
            <icon v-if="iconType(2) == 'half'" type="regular" name="faStarHalfStroke" aria-hidden="true"></icon>
            <icon v-if="iconType(2) == 'full'" type="solid" name="faStar" aria-hidden="true"></icon>
        </a>
        <a href="#" :title="title" @click.prevent="rate($event, 3)">
            <icon v-if="iconType(3) == 'empty'" type="regular" name="faStar" aria-hidden="true"></icon>
            <icon v-if="iconType(3) == 'half'" type="regular" name="faStarHalfStroke" aria-hidden="true"></icon>
            <icon v-if="iconType(3) == 'full'" type="solid" name="faStar" aria-hidden="true"></icon>
        </a>
        <a href="#" :title="title" @click.prevent="rate($event, 4)">
            <icon v-if="iconType(4) == 'empty'" type="regular" name="faStar" aria-hidden="true"></icon>
            <icon v-if="iconType(4) == 'half'" type="regular" name="faStarHalfStroke" aria-hidden="true"></icon>
            <icon v-if="iconType(4) == 'full'" type="solid" name="faStar" aria-hidden="true"></icon>
        </a>
        <a href="#" :title="title" @click.prevent="rate($event, 5)">
            <icon v-if="iconType(5) == 'empty'" type="regular" name="faStar" aria-hidden="true"></icon>
            <icon v-if="iconType(5) == 'half'" type="regular" name="faStarHalfStroke" aria-hidden="true"></icon>
            <icon v-if="iconType(5) == 'full'" type="solid" name="faStar" aria-hidden="true"></icon>
        </a>
    </span>
</template>

<script>import script from './RatingValue';
    export default script;</script>

<style lang="less">
    @import "./RatingValue.less";
</style>
