<template>
    <GMapMap ref="gMap" :center="center" :zoom="11" class="listing-map">
        <GMapMarker :clickable="true" :position="center" :icon="icon" @click="toggleMarker">
            <GMapInfoWindow :opened="showMarker">
                <h3>{{ listing.name }}</h3>

                <div class="address">
                    <hours-status :hours="listing.hours"
                        :is-shop="!listing.properties.mobileOnly && !listing.properties.managedService"></hours-status>
                    {{ listing.location.line1 }}<br />
                    {{ listing.location.line2 }}
                </div>
            </GMapInfoWindow>
        </GMapMarker>
    </GMapMap>
</template>

<script>import script from './ListingMap';
    export default script;</script>
