import context from '@truckdown/components-systems';
import { defineComponent, ref, Ref, computed, onMounted } from 'vue';
import { Listing } from '@truckdown/locations';

interface ILinkData {
    id: string,
    accountId: string,
    nameForUrl: string,
    showEditLinks: boolean,
    showReportUpdate: boolean,
    showAdminViewLinks: boolean,
    showRemoveLink: boolean,
    showUpgradeLink: boolean,
    showViewServiceLink: boolean,
    showDetailsLink: boolean,
    showWebsiteLink: boolean,
    showPhoneNumbersLink: boolean,
    isFleet: boolean,
    isAuthenticated: boolean,
    editHoursUrl?: string,
    editUrl?: string,
    notesLabel: string,
    preferredLabel: string
}

const getMethods = function (ctx: any) {
    return {
        clickShowNumbers: function (ev: Event) {
            ctx.emit('show-numbers');
        },
        clickViewServices: function (ev: Event) {
            ctx.emit('view-services');
        },
        clickViewWebsite: function (ev: Event) {
            ctx.emit('view-website');
        },
        showOnMap: function (ev: Event) {
            ctx.emit('show-on-map');
        },
        clickReportUpdate: function (ev: Event) {
            ctx.emit('report-update');
        },
        clickRemoveListing: function (ev: Event) {
            ctx.emit('remove-listing');
        },
        clickUpgradeListing: function (ev: Event) {
            ctx.emit('upgrade-listing');
        },
        clickForwardListing: function (ev: Event) {
            ctx.emit('forward-listing');
        },
        clickRateService: function (ev: Event) {
            ctx.emit('rate-service');
        },
        clickAddNote: function (ev: Event) {
            ctx.emit('add-note');
        },
        clickAddFavorite: function (ev: Event) {
            ctx.emit('favorite');
        },
        clickAddDnu: function (ev: Event) {
            ctx.emit('avoid');
        }
    };
}

export default defineComponent({
    props: {
        display: String,
        linkData: Object,
        showLinksOnly: Boolean
    },
    emits: [
        'report-update',
        'remove-listing',
        'upgrade-listing',
        'view-services',
        'view-website',
        'show-numbers',
        'favorite',
        'avoid',
        'add-note',
        'rate-service',
        'forward-listing',
        'show-on-map'
    ],
    setup: function (props, ctx) {
        let linkData = props.linkData as ILinkData;
        let methods = getMethods(ctx);

        let showMenu = ref<boolean>(false);

        return {
            showMenu,
            toggleMenu: function (ev: Event) {
                showMenu.value = !showMenu.value;
            },
            closeMenu: function (ev: Event) {
                showMenu.value = false;
            },
            ...methods
        };
    }
});
