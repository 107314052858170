<template>
    <div class="cmp-search-form">
        <div class="location td-autocomplete">
            <div :class="[locationInput && selectedCity == null ? 'focused' : '', 'autocomplete-input']">
                <div v-if="selectedCity != null" class="td-selected-input">
                    <span>
                        {{ getCityDisplay(selectedCity) }}
                        <a class="service-close" href="#" @click.prevent="selectCity()"><icon type="solid" name="faXmark" aria-hidden="true"></icon></a>
                    </span>
                </div>
                <input v-if="selectedCity == null" name="location" type="text"
                       v-model="locationInput" class="location-input td-input" :placeholder="locationPlaceholder"
                       @keyup.enter="enterLocation" @focus="focusLocations" />
                <a v-if="showCurrentLocation" class="locations-icon" href="#" @click.prevent="useCurrentLocation" aria-label="Click to use your current location">
                    <i v-if="!currentLocationLoading"><icon type="solid" name="faLocationArrow" aria-hidden="true"></icon></i>
                    <i v-if="currentLocationLoading"><icon type="solid" name="faSpinner" spin aria-hidden="true"></icon></i>
                </a>
            </div>
            <div v-if="locationError" class="td-banner danger slim no-icon"><p>{{locationError}}</p></div>
            <transition name="expand" appear>
                <div v-if="showDropdown == 'locations'" :class="[selectedCity == null && locationInput ? 'show-results' : 'hide-results', 'autocomplete-results']">
                    <div v-if="cities.length == 0" id="location-hint" class="td-banner warning slim no-icon">
                        <p>Start typing the address</p>
                    </div>
                    <div v-if="cities.length > 0" class="content">
                        <ul class="results-list">
                            <li v-for="city in cities">
                                <a href="#" @click.prevent="selectCity(city)">{{ getCityDisplay(city) }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </transition>
        </div>
        <div class="region td-autocomplete">
            <select v-model="selectedRegion" name="region" class="td-select" aria-label="Search Type">
                <option value="">State/Prov/Type (optional)</option>
                <optgroup label="United States">
                    <option v-for="rgn in usRegions" :value="rgn.code">{{ rgn.name }}</option>
                </optgroup>
                <optgroup label="Canada">
                    <option v-for="rgn in canadianRegions" :value="rgn.code">{{ rgn.name }}</option>
                </optgroup>
                <optgroup label="Other Search Types">
                    <option value="account">Account #</option>
                    <option value="phone">Phone</option>
                </optgroup>
            </select>
        </div>
        <div v-if="!isSearchType" class="service td-autocomplete">
            <div class="autocomplete-input">
                <div class="td-selected-input" name="service" v-for="service in selectedServices">
                    <span>
                        {{ service.name }}
                        <a class="service-close" href="#" @click.prevent="deselectService(service)">
                            <icon type="solid" name="faXmark" aria-hidden="true"></icon>
                        </a>
                    </span>
                </div>
                <input name="service" v-model="serviceInput" class="service-input td-input"
                       placeholder="What service do you need?" type="text" @focus="focusService"
                       @keyup.enter="enterService" />
                <i class="service-icon"><icon type="solid" name="faScrewdriverWrench" aria-hidden="true"></icon></i>
            </div>
            <div v-if="serviceError" class="td-banner slim danger no-icon"><p>{{serviceError}}</p></div>
            <div v-if="showDropdown == 'services' && (filteredServices.length > 0 || filteredNetworks.length > 0)" class="autocomplete-results service-content">
                <div class="close-content">
                    <a class="service-close" href="#" @click.prevent="closeServices">
                        <icon type="solid" name="faXmark"></icon>
                    </a>
                </div>
                <ul class="results-list">
                    <li v-if="filteredServices"><span class="td-text-uppercase">Service</span></li>
                    <li v-for="service in filteredServices">
                        <a href="#" @click.prevent="selectService(service)">{{ service.name }}</a>
                    </li>
                </ul>
                <ul class="results-list">
                    <li v-if="filteredNetworks"><span class="td-text-uppercase">Dealer Network</span></li>
                    <li v-for="network in filteredNetworks">
                        <a href="#" @click.prevent="selectService(network)">{{ network.name }}</a>
                    </li>
                </ul>
            </div>
        </div>
        <div v-if="allowOptions && hasOptions" class="options td-autocomplete">
            <button class="mb-1 td-btn primary-outlined" @click.prevent="toggleOptions">Options</button>
            <div v-if="showOptions" class="options-content">
                <div v-if="amenities.length > 0" class="filter td-autocomplete">
                    <div class="autocomplete-input">
                        <div class="td-selected-input" v-for="amenity in selectedAmenities">
                            <span>
                                {{ amenity.name }}
                                <a class="service-close" href="#" @click.prevent="deselectAmenity(amenity)">
                                    <icon type="solid" name="faXmark" aria-hidden="true"></icon>
                                </a>
                            </span>
                        </div>
                        <input name="filter" class="options-input" v-model="amenityInput"
                               placeholder="Only include locations with:" @focus="focusAmenity" type="text" />
                    </div>
                    <div v-if="showDropdown == 'amenities'" class="options-content autocomplete-results">
                        <div class="close-content">
                            <a class="close" href="#" @click.prevent="closeAmenities">
                                <icon type="solid" name="faXmark"></icon>
                            </a>
                        </div>
                        <ul class="results-list">
                            <li v-for="amenity in filteredAmenities">
                                <a href="#" @click.prevent="selectAmenity(amenity)">{{ amenity.name }}</a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-if="allowAdvanced">
                    <label class="td-checkbox-label">
                        Suppress Managed Services
                        <input name="suppress" type="checkbox" v-model="suppressManaged" />
                        <span class="mark"></span>
                    </label>
                </div>
                <div v-if="allowAdvanced">
                    <label class="td-checkbox-label">
                        Search on Distance
                        <input name="distance" type="checkbox" v-model="searchOnDistance" />
                        <span class="mark"></span>
                    </label>
                </div>
            </div>
        </div>
        <div class="search-btn-wrapper">
            <captcha v-if="showCaptcha" @success="captchaSuccess" instance="search-form"></captcha>
            <div v-else-if="isBlocked" class="td-banner warning no-icon">
                <p><strong>Security Alert:</strong> you have been blocked by our security systems.  Please <a href="/contact" title="Contact Us">contact us</a>.</p>
                <p>For more information about why we limit access, please read <a href="/why-we-limit-access">why we limit access</a>.</p>
            </div>
            <button v-else class="td-btn primary td-full-width search-btn" @click="runSearch">Search</button>
        </div>
    </div>
</template>

<script>import script from './SearchForm';
    export default script;</script>

<style lang="less">
    @import "./SearchForm.less";
</style>

